<template>
  <Layout>
    <div :class="['agcontainer']">
      <h1 class="title">
        {{ content.ag_title }}
      </h1>
      <YearSelect
        ref="yearslider"
        @ageset="ageset"
      />
      <div class="actions">
        <Button
          :enable="isButtonEnabled"
          :color="isButtonEnabled ? 'green' : 'grey'"
          :text="content.a_ok"
          @clicked="ok"
        />
        <ExternalLink
          :url="policyUrl"
          :text="content.v_pp"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import * as Sentry from '@sentry/vue'
import YearSelect from '@components/agegate/YearSelect.vue'
import Button from '@components/Button.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Age Gate',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Age Gate',
        },
      ],
    }
  },
  components: {
    YearSelect,
    Button,
    ExternalLink,
  },
  data() {
    return {
      yearSet: false,
      consented: false,
      isPiplEnabled: false,
      content: {},
      policyUrl: '',
    }
  },
  computed: {
    isButtonEnabled() {
      if (this.isPiplEnabled) return this.yearSet && this.consented
      else return this.yearSet
    },
  },
  created() {
    this.$compliance.initCollector('age_gate')
  },
  mounted() {
    Sentry.addBreadcrumb({
      type: 'user',
      message: '[Age Gate] Shown',
      timestamp: new Date(),
    })
    this.$compliance.AgeGate.Slider = this.$refs.yearslider
    this.content = this.$compliance.getLocalisedContent()
    this.$compliance.privacyUrl().then((pp) => {
      this.policyUrl = pp
    })
  },
  methods: {
    ageset(isSet) {
      this.yearSet = isSet
    },
    ok() {
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message:
          '[Age Gate] OK | enabled: ' +
          (this.$compliance.AgeGate.isAgeSet() && this.isButtonEnabled),
        timestamp: new Date(),
      })
      if (this.$compliance.AgeGate.isAgeSet() && this.isButtonEnabled) {
        Sentry.addBreadcrumb({
          type: 'user',
          category: 'resolution',
          message: '[Age Gate] OK',
          timestamp: new Date(),
        })
        this.$compliance.AgeGate.confirm()
      }
    },
    toggleConsent(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true
      this.consented = !this.consented
      this.$compliance.native('onEvent', {
        checkboxChecked: this.consented,
        send: true,
      })
    },
  },
}
</script>

<style lang="scss">
.agcontainer {
  position: relative;
  background: #fff;
  height: 100%;
  width: 100%;

  .logo {
    background-image: url('../../assets/images/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 40px;
    margin: 20px auto 0 auto;
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 15vh auto 15vh auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .actions {
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: 10%;
    .btn {
      margin: 0 auto 5% auto;
    }
  }
}
.landscape {
  .title {
    margin: 10vh auto 10vh auto;
  }
}
.landscape {
  .layout .agcontainer {
    .actions {
      bottom: 2%;
      .btn {
        margin: 0 auto 0 auto;
      }
    }
    .title {
      margin: 10vh auto 10vh auto;
      @media screen and (min-width: 768px) {
        font-size: 2.6em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 3.6em;
      }
    }
    .yearselect {
      .glider {
        @media screen and (min-width: 768px) {
          height: 100px;
        }
        @media screen and (min-width: 1024px) {
          height: 180px;
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        @media screen and (min-width: 768px) {
          top: 20%;
          width: 36px;
          height: 50px;
        }
        @media screen and (min-width: 1024px) {
          width: 48px;
          height: 69px;
        }
      }
      .yearnumber {
        @media screen and (min-width: 768px) {
          font-size: 1.6em;
        }
        @media screen and (min-width: 1024px) {
          font-size: 2.6em;
        }
        &.center,
        &.glide__slide--active {
          @media screen and (min-width: 768px) {
            font-size: 3.4em;
          }
          @media screen and (min-width: 1024px) {
            font-size: 5.4em;
          }
        }
      }
    }
  }
  &.tablet {
    .layout {
      .title {
        @media screen and (min-width: 768px) {
          font-size: 2.6em;
        }
        @media screen and (min-width: 1024px) {
          font-size: 3.6em;
        }
      }
      .yearselect {
        margin: 9vh 20px 1vh 20px;
        .glider {
          @media screen and (min-width: 768px) {
            height: 100px;
          }
          @media screen and (min-width: 1024px) {
            height: 180px;
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          @media screen and (min-width: 768px) {
            top: 20%;
            width: 32px;
            height: 46px;
          }
          @media screen and (min-width: 1024px) {
            width: 48px;
            height: 69px;
          }
        }
        .yearnumber {
          @media screen and (min-width: 768px) {
            font-size: 1.6em;
          }
          @media screen and (min-width: 1024px) {
            font-size: 2.6em;
          }
          &.center,
          &.glide__slide--active {
            @media screen and (min-width: 768px) {
              font-size: 3.4em;
            }
            @media screen and (min-width: 1024px) {
              font-size: 5.4em;
            }
          }
        }
      }
    }
  }
}
</style>
