<template>
  <div class="yearselect glide">
    <div
      class="swiper-container glider glide__track"
      data-glide-el="track"
    >
      <ul class="glide__slides">
        <li
          v-for="(slide, index) in slides"
          :key="index"
          :data-year="slide.year"
          class="yearnumber glide__slide"
        >
          {{ slide.year }}
        </li>
      </ul>
    </div>
    <button
      ref="swiper-button-prev"
      :class="['swiper-button-prev', animatedL ? 'btn_effect' : '']"
      @touchstart="start($event)"
      @touchmove="move($event)"
      @touchend="end($event)"
      @click="start($event)"
    />
    <button
      ref="swiper-button-next"
      :class="['swiper-button-next', animatedR ? 'btn_effect' : '']"
      @touchstart="start($event)"
      @touchmove="move($event)"
      @touchend="end($event)"
      @click="start($event)"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
import '@glidejs/glide/src/assets/sass/glide.core.scss'

export default {
  name: 'YearSelect',
  data() {
    return {
      slider: null,
      activeIndex: 0,
      yearNow: 0,
      rangeSize: 121,
      renderedSlides: 7,
      years: [],
      selected: 0,
      slides: [],
      animatedL: false,
      animatedR: false,
      pressTimer: null,
      keydownTimer: null,
    }
  },
  created() {
    this.yearNow = new Date().getFullYear()
  },
  mounted() {
    console.log('age gate mounted')
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  },
  beforeDestroy() {
    this.slider.destroy()
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  },
  methods: {
    init() {
      // set selection range and min/max boundaries
      this.years = range(this.rangeSize, this.yearNow, this.selected)
      this.slides = []
      for (const y in this.years) {
        this.slides.push(this.slide(this.years[y]))
      }
    },
    update() {
      if (this.slider) this.slider.destroy() // cleanup if it already exists
      this.selected = this.$compliance.AgeGate.getDefaultYear() // determine selected year
      this.init() // init slides

      // (re)initialise slider component
      // we have to do this after DOM rendered with new slides (GlideJS bug!!)
      // https://github.com/glidejs/glide/issues/337
      this.$nextTick(() => {
        this.slider = new Glide('.glide', {
          type: 'carousel',
          startAt: this.yearNow - this.selected,
          perView: 3,
          focusAt: 'center',
          animationDuration: 40,
          gap: 0,
          throttle: 10,
        })
        try {
          this.slider.mount({ Controls, Swipe })
          // bind events, etc.
        } catch (e) {
          console.log(e)
          // handle it however you'd like
        }

        // register event listener on move
        this.slider.on('move.after', (movement) => {
          if (this.slider.index !== this.activeIndex) {
            this.activeIndex = this.slider.index
            // custom data located at `event.detail`
            // access to Glider object via `Glider(this)`
            // get index of centered slide
            // translate by +1 as we are doing the lookup in 0 indexed array
            const i = Number(this.activeIndex)
            Sentry.addBreadcrumb({
              type: 'user',
              category: 'ui.click',
              message: '[Age Gate] Year: ' + this.slides[i].year,
              timestamp: new Date(),
            })
            if (this.slides[i].year !== '----') {
              this.$compliance.AgeGate.setAgeGate(this.slides[i].year)
              this.$emit('ageset', true)
            } else {
              this.$compliance.AgeGate.setAgeGate(-1)
              this.$emit('ageset', false)
            }
          }
        })
      })
    },
    handleKeyDown(e) {
      // Keydown handler for PC games, matches the "long touch" behaviour for "long press" events
      if (this.keydownTimer == null) {
        if (e.key.toLowerCase() === 'a') {
          this.slider.go('<')
          this.keydownTimer = setInterval(() => {
            this.slider.go('<')
          }, 140)
        } else if (e.key.toLowerCase() === 'd') {
          this.slider.go('>')
          this.keydownTimer = setInterval(() => {
            this.slider.go('>')
          }, 140)
        }
      }
    },
    handleKeyUp() {
      clearInterval(this.keydownTimer)
      this.keydownTimer = null
    },
    slide(year) {
      return {
        year: year,
      }
    },
    handler(target) {
      if (target === 'swiper-button-next') this.slider.go('>')
      if (target === 'swiper-button-prev') this.slider.go('<')
    },
    start(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      const target = event.target.className.split(' ')[0]

      if (target === 'swiper-button-next') this.animatedR = true
      if (target === 'swiper-button-prev') this.animatedL = true

      this.handler(target) // handle click
      if (this.pressTimer === null) {
        // enable long press only on touch enabled devices
        if (event.type !== "click") {
          this.pressTimer = setInterval(() => {
            // handle long press
            this.handler(target)
          }, 140)
        }
      }
    },
    move(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      const target = event.target.className.split(' ')[0]
      if (!this.determineTouchzone(event, target)) {
        clearInterval(this.pressTimer)
      }
    },
    determineTouchzone(event, btnRef) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") return true
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs[btnRef]
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        return true
      else return false
    },
    end(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      const target = event.target.className.split(' ')[0]

      if (target === 'swiper-button-next') this.animatedR = false
      if (target === 'swiper-button-prev') this.animatedL = false
      if (this.pressTimer !== null) {
        clearTimeout(this.pressTimer)
        this.pressTimer = null
      }
    },
  },
}

const range = (size, startAt = 0, selected = false) => {
  var a = []
  for (var i = 0; i < size; i++) {
    a.push(startAt - i)
  }
  if (selected) a.splice(startAt - selected, 0, '----')
  return a
}
</script>

<style lang="scss">
.yearselect {
  position: absolute;
  width: calc(100% - 40px);
  margin: 20px;
  background: #fff;
  direction: ltr;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  .glider {
    position: relative;
    width: 100%;
    height: 100px;
    @media screen and (min-width: 768px) {
      height: 180px;
    }
  }
  .glider::-webkit-scrollbar {
    display: none;
  }
  .glide__slides {
    margin: 0;
  }
  .yearnumber {
    min-width: 50px; /* override for glider-js */
    margin-top: 0;
    font-size: 1.2em;
    color: #9b9b9b;
    text-align: center;
    text-transform: uppercase;
    transition: all;
    transition-duration: 0.04s;
    @media screen and (min-width: 320px) {
      font-size: 1.4em;
    }
    @media screen and (min-width: 425px) {
      font-size: 1.6em;
    }
    @media screen and (min-width: 768px) {
      font-size: 2.6em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 3.6em;
    }

    /* width: 33.3% !important;  override for glider-js */
    &.center,
    &.glide__slide--active {
      font-size: 2em;
      color: #4f5c68;
      @media screen and (min-width: 320px) {
        font-size: 2.6em;
      }
      @media screen and (min-width: 425px) {
        font-size: 3.4em;
      }
      @media screen and (min-width: 768px) {
        font-size: 5.4em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 7.4em;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 16%;
    width: 36px;
    height: 50px;
    text-indent: -9000px;
    border: none;
    background-color: transparent;
    background-image: url('../../assets/images/btn_arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 0.2s ease-in-out;

    @media screen and (min-width: 768px) {
      width: 48px;
      height: 69px;
    }
    @media screen and (min-width: 1024px) {
      width: 64px;
      height: 92px;
    }
  }
  .swiper-button-prev {
    left: -20px;
    transform: rotate(180deg);
  }
  .swiper-button-next {
    right: -20px;
  }
  .btn_effect.swiper-button-prev {
    transform: scale(0.9) rotate(180deg);
  }
  .btn_effect.swiper-button-next {
    transform: scale(0.9);
  }
  .glider-track {
    scrollbar-width: none;
  }
  @-moz-document url-prefix() {
    .glider-track {
      margin-bottom: 17px;
    }
    .glider-wrap {
      overflow: hidden;
    }
  }
}
.landscape .yearselect {
  width: calc(100% - 160px);
  left: 60px;
}
.landscape .swiper-button-prev {
  left: -40px;
}
.landscape .swiper-button-next {
  right: -40px;
}
</style>
